import { createSlice } from "@reduxjs/toolkit";

export const parleySlice = createSlice({
    name: "parleySlice",
    initialState: {
        items: [],
        status: "idle",
        error: null
    },
    reducers: {
        addEventInfo: (state, action)=>{
            if (!Array.isArray(state.items)) {
                state.items = [];
            }
            const data = action.payload;
            state.items.push(action.payload);
        },
        resetEventInfo: (state)=>{
            state.items = [];
        },
        removeEvent: (state, action)=>{
            state.items.splice(action.payload, 1);
        }
    }
})

export const { getData, setData } = parleySlice;
export const {addEventInfo, resetEventInfo, removeEvent} = parleySlice.actions;

export default parleySlice.reducer;