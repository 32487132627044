import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { RoutesAndLayouts } from './Components/Routes&Layouts/RoutesAndLayouts';
import MyErrorBoundary from './Components/ErrorBoundry/ErrorBoundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UseContext } from './Components/Context/UseContext';
import { Auth0Provider } from './Components/AuthProvider/AuthProvider';
import { Firebase } from './Components/Firebase/Firebase';

function App() {

  return (
    <Auth0Provider >
      <UseContext >
        <BrowserRouter>
          <MyErrorBoundary>
            <RoutesAndLayouts />
          </MyErrorBoundary>
          <ToastContainer />
          <Firebase />
        </BrowserRouter>
      </UseContext>
    </Auth0Provider>
  );
}

export default App;
