import menWithPhone from '../../Assets/man-with-phone.png'
import eyeIcon from '../../Assets/eye-icon.png'
import cutIcon from '../../Assets/cut-icon.png'
import redCutIcon from '../../Assets/red-cut-icon.png'
import google from '../../Assets/Google.png'
import hamburgerOpen from '../../Assets/Sidebar/hamburger-open.png'
import hamburgerClose from '../../Assets/Sidebar/hamburger-close.png'
import homeBlack from '../../Assets/Sidebar/black/home.png'
import activeBetsBlack from '../../Assets/Sidebar/black/active-bets.png'
import sportsBlack from '../../Assets/Sidebar/black/sports.png'
import politicsBlack from '../../Assets/Sidebar/black/politics.png'
import entertainmentBlack from '../../Assets/Sidebar/black/entertainment.png'
import weatherBlack from '../../Assets/Sidebar/black/weather.png'
import esportsBlack from '../../Assets/Sidebar/black/Esports.png'
import socialMediaBlack from '../../Assets/Sidebar/black/Social Media.png'
import celebritiesBlack from '../../Assets/Sidebar/black/Celebrities.png'
import tvShowsBlack from '../../Assets/Sidebar/black/TV Shows.png'
import stocksBlack from '../../Assets/Sidebar/black/Stocks.png'
import cryptocurrenciesBlack from '../../Assets/Sidebar/black/Cryptocurrencies.png'
import lottoBlack from '../../Assets/Sidebar/black/Lotto.png'
import homeRed from '../../Assets/Sidebar/red/home.png'
import activeBetsRed from '../../Assets/Sidebar/red/active-bets.png'
import sportsRed from '../../Assets/Sidebar/red/sports.png'
import politicsRed from '../../Assets/Sidebar/red/politics.png'
import entertainmentRed from '../../Assets/Sidebar/red/entertainment.png'
import weatherRed from '../../Assets/Sidebar/red/weather.png'
import esportsRed from '../../Assets/Sidebar/red/Esports.png'
import socialMediaRed from '../../Assets/Sidebar/red/Social Media.png'
import celebritiesRed from '../../Assets/Sidebar/red/Celebrities.png'
import tvShowsRed from '../../Assets/Sidebar/red/TV Shows.png'
import stocksRed from '../../Assets/Sidebar/red/Stocks.png'
import cryptocurrenciesRed from '../../Assets/Sidebar/red/Cryptocurrencies.png'
import lottoRed from '../../Assets/Sidebar/red/Lotto.png'
import searchIcon from '../../Assets/Navbar/search-icon.png'
import notificationIcon from '../../Assets/Navbar/notification-icon.png'
import dummyProfileImg from '../../Assets/Navbar/dummy-profile-img.jpg'
import starIcon from '../../Assets/Navbar/star.png'
import profileIcon from '../../Assets/Navbar/profile-icon.png'
import settingIcon from '../../Assets/Navbar/setting-icon.png'
import logoutIcon from '../../Assets/Navbar/logout-icon.png'
import walletIcon from '../../Assets/Navbar/wallet_icon.png'
import bannerOne from '../../Assets/Crousel/banner-1.png'
import bannerTwo from '../../Assets/Crousel/banner-2.png'
import imageOne from '../../Assets/Cards/image (1).png'
import parking from '../../Assets/Cards/parking.png'
import comment from '../../Assets/Cards/comment.png'
import commented from '../../Assets/Cards/commented.png'
import star from '../../Assets/Cards/star.png'
import yelloStar from '../../Assets/Cards/yello-star-Icon.png'
import prev from '../../Assets/Pagination/prev.png'
import next from '../../Assets/Pagination/next.png'
import previousPage from '../../Assets/Pagination/previous-page.png'
import nextPage from '../../Assets/Pagination/next-page.png'
import sortIcon from '../../Assets/sort-icon.png'
import selectedIcon from '../../Assets/selected-icon.png'
import unSelectedIcon from '../../Assets/unselected-icon.png'
import cardDetailBack from '../../Assets/Cards/card-back.png'
import cardDetailTimer from '../../Assets/Cards/card-detail-timer.png'
import detImgOne from '../../Assets/Cards/det-img-one.png'
import detImgTwo from '../../Assets/Cards/det-img-two.png'
import like from '../../Assets/Cards/like.png'
import liked from '../../Assets/Cards/liked.png'
import noEvents from '../../Assets/Cards/no-events.png'
import profileImg from '../../Assets/Profile/profile-img-icon.png'
import Time from '../../Assets/time.png'
import editIcon from '../../Assets/Profile/edit-icon.png'
import totalBets from '../../Assets/Profile/total-bet.png'
import totalWon from '../../Assets/Profile/total-won.png'
import yelloDot from '../../Assets/Profile/yello-dot.png'
import greenDot from '../../Assets/Profile/green-dot.png'
import redDot from '../../Assets/Profile/red-dot.png'
import redProfileIcon from '../../Assets/Profile/red-profile.png'
import redNotificationIcon from '../../Assets/Profile/red-notification.png'
import plusIcon from '../../Assets/MyWallet/plus-icon.png'
import withdrawMoney from '../../Assets/MyWallet/withdraw-money.png'
import redeemCoupon from '../../Assets/MyWallet/redeem-coupon.png'
import copyIcon from '../../Assets/MyWallet/copyIcon.png'
import usdtIcon from '../../Assets/MyWallet/usdtIcon.png'
import usdtColorIcon from '../../Assets/MyWallet/usdt-icon.png'
import refreshIcon from '../../Assets/MyWallet/refresh-icon.png'
import filterIcon from '../../Assets/MyWallet/filter-icon.png'
import dateIcon from '../../Assets/MyWallet/date-icon.png'
import arrowLeft from '../../Assets/MyWallet/arrow-letf.png'
import arrowRight from '../../Assets/MyWallet/arrow-right.png'
import akalaKoLogo from '../../Assets/akala-ko-logo.png'
import withdrawIcon from '../../Assets/MyWallet/withdrawal-amount-icon.png'
import withdrawIconWhite from '../../Assets/MyWallet/withdraw-icon-white.png'
import bonusIcon from '../../Assets/MyWallet/bonus-amount-icon.png'
import pencilIcon from '../../Assets/pencil-icon.svg'
import successIcon from '../../Assets/Cards/success-icon.png'
import errorIcon from '../../Assets/Cards/error-icon.png'
import addIconBlue from '../../Assets/Cards/Add Icon Blue.png'
import homeActive from '../../Assets/MobileMenu/home-active.png'
import homeInActive from '../../Assets/MobileMenu/home-inactive.png'
import searchActive from '../../Assets/MobileMenu/search-active.png'
import searchInActive from '../../Assets/MobileMenu/search-inactive.png'
import hotspotActive from '../../Assets/MobileMenu/hotspot-active.png'
import hotspotInActive from '../../Assets/MobileMenu/hotspot-inactive.png'
import settingActive from '../../Assets/MobileMenu/settings-active.png'
import settingInActive from '../../Assets/MobileMenu/settings-inactive.png'
import parleyIcon from '../../Assets/Parley/parley-icon.png'
import ReferIcon from '../../Assets/Navbar/share.png'
import AffiliateIcon from '../../Assets/Navbar/affiliate.png'
import downloadIcon from '../../Assets/download-icon.png'
import Reward from '../../Assets/referal/reward.png'
import TotalReferal from '../../Assets/referal/total_referal.png'
import ActiveReferal from '../../Assets/referal/active_referal.png'
import AkalaAffProgram from '../../Assets/referal/akala_aff_prog.png'
import Money from '../../Assets/referal/money.png'
import Facebook from '../../Assets/referal/facebook.png'
import Insta from '../../Assets/referal/instagram.png'
import Skype from '../../Assets/referal/skype.png'
import Whatsapp from '../../Assets/referal/whatsapp.png'
import Twitter from '../../Assets/referal/x.png'
import refCopyIcon from '../../Assets/referal/copy-icon.png'
import GCashBlack from '../../Assets/Payment/Black/GCashMayaBanktransfer.png'
import directDebitBlack from '../../Assets/Payment/Black/Direct Debit.png'
import overTheCounterBlack from '../../Assets/Payment/Black/Over the Counter.png'
import debitCreditCardBlack from '../../Assets/Payment/Black/Debit  Credit Card.png'
import GCashBlue from '../../Assets/Payment/Blue/GCashMayaBanktransfer.png'
import directDebitBlue from '../../Assets/Payment/Blue/Direct Debit.png'
import overTheCounterBlue from '../../Assets/Payment/Blue/Over the Counter.png'
import debitCreditCardBlue from '../../Assets/Payment/Blue/Debit  Credit Card.png'
import paymentCards from '../../Assets/Payment/Cards.png'
import processDeposit from '../../Assets/Payment/Proceed Deposit.png'
import pesoIcon from '../../Assets/Peso Icon.png'
import TickIcon from '../../Assets/checked.png'
import CblBtnIcon from '../../Assets/Parley/cbl-btn-icon.png'
import CblBtnRedIcon from '../../Assets/Parley/cbl-btn-red-icon.png'
import addIconRed from '../../Assets/Parley/Add icon red.png'
import deleteIconRed from '../../Assets/Parley/Delete Red.png'
import GCash from '../../Assets/Footer/gcash.png'
import Maya from '../../Assets/Footer/maya.png'
import Qrph from '../../Assets/Footer/qrph.png'
import Cebuana from '../../Assets/Footer/cebuana.png'
import InstaPay from '../../Assets/Footer/instapay.png'
import Bdo from '../../Assets/Footer/bdo.png'
import SevenEleven from '../../Assets/Footer/sevenEleven.png'
import TelegramLogo from '../../Assets/Footer/Telegram.png'
import AboutUsBanner from '../../Assets/AboutUs/aboutus-bg.png'
import AboutUsCoin from '../../Assets/AboutUs/peso-coin.png'

export const Images = {
    menWithPhone: menWithPhone,
    eyeIcon: eyeIcon,
    cutIcon: cutIcon,
    redCutIcon: redCutIcon,
    google: google,
    sortIcon: sortIcon,
    selectedIcon: selectedIcon,
    unSelectedIcon: unSelectedIcon,
    akalaKoLogo: akalaKoLogo,
    pencilIcon: pencilIcon,
    downloadIcon: downloadIcon,
    pesoIcon: pesoIcon,
    sidebar: {
        hamburgerOpen: hamburgerOpen,
        hamburgerClose: hamburgerClose,
        home: { black: homeBlack, red: homeRed },
        activeBets: { black: activeBetsBlack, red: activeBetsRed },
        sports: { black: sportsBlack, red: sportsRed },
        politics: { black: politicsBlack, red: politicsRed },
        entertainment: { black: entertainmentBlack, red: entertainmentRed },
        weather: { black: weatherBlack, red: weatherRed },
        esports: { black: esportsBlack, red: esportsRed },
        socialMedia: { black: socialMediaBlack, red: socialMediaRed },
        celebrities: { black: celebritiesBlack, red: celebritiesRed },
        tvShows: { black: tvShowsBlack, red: tvShowsRed },
        stocks: { black: stocksBlack, red: stocksRed },
        cryptocurrencies: { black: cryptocurrenciesBlack, red: cryptocurrenciesRed },
        lotto: { black: lottoBlack, red: lottoRed },
    },
    navbar: {
        searchIcon: searchIcon,
        notificationIcon: notificationIcon,
        dummyProfileImg: dummyProfileImg,
        profileIcon: profileIcon,
        settingIcon: settingIcon,
        referIcon: ReferIcon,
        affiliateIcon: AffiliateIcon,
        logoutIcon: logoutIcon,
        starIcon: starIcon,
        walletIcon: walletIcon,
    },
    crousel: {
        bannerOne: bannerOne,
        bannerTwo: bannerTwo
    },
    cards: {
        imageOne: imageOne,
        parking: parking,
        comment: comment,
        commented: commented,
        star: star,
        yelloStar: yelloStar,
        like: like,
        liked: liked,
        cardDetailBack: cardDetailBack,
        cardDetailTimer: cardDetailTimer,
        detImgOne: detImgOne,
        detImgTwo: detImgTwo,
        time: Time,
        noEvents: noEvents,
        successIcon: successIcon,
        errorIcon: errorIcon,
        addIconBlue: addIconBlue
    },
    pagination: {
        prev: prev,
        next: next,
        previousPage: previousPage,
        nextPage: nextPage
    },
    profile: {
        profileImg: profileImg,
        editIcon: editIcon,
        totalBets: totalBets,
        totalWon: totalWon,
        yelloDot: yelloDot,
        greenDot: greenDot,
        redDot: redDot,
        redProfileIcon: redProfileIcon,
        redNotificationIcon: redNotificationIcon
    },
    myWallet: {
        plusIcon: plusIcon,
        withdrawMoney: withdrawMoney,
        redeemCoupon: redeemCoupon,
        copyIcon: copyIcon,
        usdtIcon: usdtIcon,
        usdtColorIcon: usdtColorIcon,
        refreshIcon: refreshIcon,
        filterIcon: filterIcon,
        dateIcon: dateIcon,
        arrowLeft: arrowLeft,
        arrowRight: arrowRight,
        withdrawIcon: withdrawIcon,
        withdrawIconWhite: withdrawIconWhite,
        bonusIcon: bonusIcon,
        tickIcon: TickIcon,
    },
    mobileMenu: {
        homeActive: homeActive,
        homeInActive: homeInActive,
        searchActive: searchActive,
        searchInActive: searchInActive,
        hotspotActive: hotspotActive,
        hotspotInActive: hotspotInActive,
        settingActive: settingActive,
        settingInActive: settingInActive
    },
    parley: {
        parleyIcon: parleyIcon,
        cblBtnIcon: CblBtnIcon,
        cblBtnRedIcon: CblBtnRedIcon,
        addIconRed: addIconRed,
        deleteIconRed: deleteIconRed,
    },
    referal: {
        Reward: Reward,
        TotalReferal: TotalReferal,
        ActiveReferal: ActiveReferal,
        AkalaAffProgram: AkalaAffProgram,
        Money: Money,
        Facebook: Facebook,
        Insta: Insta,
        Skype: Skype,
        Whatsapp: Whatsapp,
        Twitter: Twitter,
        refCopyIcon: refCopyIcon
    },
    payment: {
        black: {
            GCash: GCashBlack,
            directDebit: directDebitBlack,
            overTheCounter: overTheCounterBlack,
            debitCreditCard: debitCreditCardBlack,
        },
        blue: {
            GCash: GCashBlue,
            directDebit: directDebitBlue,
            overTheCounter: overTheCounterBlue,
            debitCreditCard: debitCreditCardBlue,
        },
        cards: paymentCards,
        processDeposit: processDeposit,
    },
    footer: {
        GCash: GCash,
        Maya: Maya,
        Qrph: Qrph,
        Cebuana: Cebuana,
        InstaPay: InstaPay,
        Bdo: Bdo,
        SevenEleven: SevenEleven,
        TelegramLogo: TelegramLogo
    },
    aboutus: {
        background: AboutUsBanner,
        coin: AboutUsCoin
    }
}