import React from 'react';
import auth0Config from '../../Utils/auth0-config';
import { Auth0Provider as _Auth0Provider } from '@auth0/auth0-react';

export const Auth0Provider = ({ children }) => {

    return (
        <_Auth0Provider
            domain={auth0Config.domain}
            clientId={auth0Config.clientId}
            redirectUri={auth0Config.redirectUri}
            // audience={auth0Config.audience}
            cacheLocation="localstorage"
            useRefreshTokens={true}
            useRefreshTokensFallback={false}
            authorizationParams={{
                redirect_uri: auth0Config.redirectUri,
                audience: auth0Config.audience
            }}
        >
            {children}
        </_Auth0Provider>
    );
};
