// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-mobile-menu {
    background: rgba(210, 43, 43, 1);
    box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px 16px 0px 0px;
    display: flex;
    justify-content: space-between;
}

.menu-img-div img {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.menu-img-div {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
}

.menu-img-div-main {
    width: 66px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.border-menu {
    border-top: 2px solid rgba(255, 255, 255, 1);
    height: 100%;
    align-items: center;
    display: flex;
}

.main-mobile-menu {
    position: fixed;
    width: 100%;
    bottom: 0;
}
.menu-img-div{
    opacity: 0.5;
    cursor: pointer;
}
.menu-img-div:hover{
    opacity: 0.8;
}
.border-menu .menu-img-div {
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/Components/MobileMenu/MobileMenu.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,gDAAgD;IAChD,gCAAgC;IAChC,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,4CAA4C;IAC5C,YAAY;IACZ,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,SAAS;AACb;AACA;IACI,YAAY;IACZ,eAAe;AACnB;AACA;IACI,YAAY;AAChB;AACA;IACI,UAAU;AACd","sourcesContent":[".main-mobile-menu {\n    background: rgba(210, 43, 43, 1);\n    box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.1);\n    border-radius: 16px 16px 0px 0px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.menu-img-div img {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.menu-img-div {\n    width: 24px;\n    height: 24px;\n    display: flex;\n    align-items: center;\n}\n\n.menu-img-div-main {\n    width: 66px;\n    height: 48px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.border-menu {\n    border-top: 2px solid rgba(255, 255, 255, 1);\n    height: 100%;\n    align-items: center;\n    display: flex;\n}\n\n.main-mobile-menu {\n    position: fixed;\n    width: 100%;\n    bottom: 0;\n}\n.menu-img-div{\n    opacity: 0.5;\n    cursor: pointer;\n}\n.menu-img-div:hover{\n    opacity: 0.8;\n}\n.border-menu .menu-img-div {\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
