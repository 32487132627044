// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.coming-soon-main {
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    align-items: center;
    text-align: center;
    font-family: Montserrat;
    font-size: var(--font-14px);
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    padding: 8px 0;
    margin-top: 20px;
}
.tab-select-main {
    background: rgba(255, 255, 255, 1);
    padding: 5px 10px;
    border-radius: 45px;
    font-family: Montserrat;
    font-size: var(--font-14px);
    font-weight: 500;
    color: rgba(210, 43, 43, 1);
    display: flex;
    align-items: center;
    width: fit-content;
}

.tab-select-main .tab-item {
    padding: 10px 20px;
    transition: all 0.3s ease-in-out; 
    cursor: pointer; 
    margin: 0 5px;
}

.tab-select-main .tab-item:hover {
    opacity: 0.8;
    border-radius: 30px;
}

.tab-select-main .active {
    background: rgba(210, 43, 43, 1);
    border-radius: 60px;
    padding: 8px 20px;
    color: rgba(255, 255, 255, 1);
    transition: all 0.3s ease-in-out; 
}

@media screen and (max-width: 768px) {
    .tab-select-main {
        font-size: var(--font-12px);
        margin: 15px 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/ActiveBets/ActiveBets.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;;;AAIA;IACI,wCAAwC;IACxC,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,2BAA2B;IAC3B,gBAAgB;IAChB,0BAA0B;IAC1B,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,kCAAkC;IAClC,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,2BAA2B;IAC3B,gBAAgB;IAChB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;IAChC,mBAAmB;IACnB,iBAAiB;IACjB,6BAA6B;IAC7B,gCAAgC;AACpC;;AAEA;IACI;QACI,2BAA2B;QAC3B,cAAc;IAClB;AACJ","sourcesContent":[".content-wrapper {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n\n\n.coming-soon-main {\n    background-color: rgba(255, 255, 255, 1);\n    width: 100%;\n    align-items: center;\n    text-align: center;\n    font-family: Montserrat;\n    font-size: var(--font-14px);\n    font-weight: 500;\n    color: rgba(51, 51, 51, 1);\n    padding: 8px 0;\n    margin-top: 20px;\n}\n.tab-select-main {\n    background: rgba(255, 255, 255, 1);\n    padding: 5px 10px;\n    border-radius: 45px;\n    font-family: Montserrat;\n    font-size: var(--font-14px);\n    font-weight: 500;\n    color: rgba(210, 43, 43, 1);\n    display: flex;\n    align-items: center;\n    width: fit-content;\n}\n\n.tab-select-main .tab-item {\n    padding: 10px 20px;\n    transition: all 0.3s ease-in-out; \n    cursor: pointer; \n    margin: 0 5px;\n}\n\n.tab-select-main .tab-item:hover {\n    opacity: 0.8;\n    border-radius: 30px;\n}\n\n.tab-select-main .active {\n    background: rgba(210, 43, 43, 1);\n    border-radius: 60px;\n    padding: 8px 20px;\n    color: rgba(255, 255, 255, 1);\n    transition: all 0.3s ease-in-out; \n}\n\n@media screen and (max-width: 768px) {\n    .tab-select-main {\n        font-size: var(--font-12px);\n        margin: 15px 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
