import { i18n } from "@lingui/core"

export const locales = {
    en: "English",
    fil: "Filipino"
}

const lang = localStorage.getItem("selectedLanguage");

  if(lang === null){
    localStorage.setItem("selectedLanguage", "en")
  }

export const defaultLocale = localStorage.getItem("selectedLanguage");

const catalogs = {
    en: async () => {
      const { messages } = await import(
        `./file.js!=!@lingui/loader!./locales/en/messages.po`
      )
      return messages;
    },

    fil: async ()=>{
      const {messages} = await import(
        `./file.js!=!@lingui/loader!./locales/fil/messages.po`
      )
      return messages;
    }
}

export async function dynamicActivate(locale) {
  const messages = await catalogs[locale]()
  // i18n.loadAndActivate({ locale, messages })
  i18n.load({ [locale]: messages });
  i18n.activate(locale);
}
