// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dots > .inactive-dot{
    background: rgba(169, 169, 169, 0.5);
    height: 7px;
    width: 10px;
    border-radius: 30px;
    cursor: pointer;
}
.dots > .active-dot{
    background: rgba(169, 169, 169, 0.5);
    height: 7px;
    width: 25px;
    border-radius: 30px;
    cursor: pointer;
}
.crousel-img-list{
    width: 100%;
}
@media screen and (min-width: 600px) {
    .crousel-img-list > img{   
        height: 270px;
    }
}
@media screen and (min-width: 1000px) {
    .crousel-img-list > img{   
        height: 300px;
    }
}
@media screen and (min-width: 1300px) {
    .crousel-img-list > img{   
        height: 320px;
    }
}
@media screen and (min-width: 1500px) {
    .crousel-img-list > img{   
        height: 350px;
    }
}
@media screen and (min-width: 1700px) {
    .crousel-img-list > img{   
        height: 410px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Crousel/Crousel.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,oCAAoC;IACpC,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,WAAW;AACf;AACA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".dots > .inactive-dot{\n    background: rgba(169, 169, 169, 0.5);\n    height: 7px;\n    width: 10px;\n    border-radius: 30px;\n    cursor: pointer;\n}\n.dots > .active-dot{\n    background: rgba(169, 169, 169, 0.5);\n    height: 7px;\n    width: 25px;\n    border-radius: 30px;\n    cursor: pointer;\n}\n.crousel-img-list{\n    width: 100%;\n}\n@media screen and (min-width: 600px) {\n    .crousel-img-list > img{   \n        height: 270px;\n    }\n}\n@media screen and (min-width: 1000px) {\n    .crousel-img-list > img{   \n        height: 300px;\n    }\n}\n@media screen and (min-width: 1300px) {\n    .crousel-img-list > img{   \n        height: 320px;\n    }\n}\n@media screen and (min-width: 1500px) {\n    .crousel-img-list > img{   \n        height: 350px;\n    }\n}\n@media screen and (min-width: 1700px) {\n    .crousel-img-list > img{   \n        height: 410px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
