import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../Components/Sidebar/Sidebar';
import { Navbar } from '../Components/Navbar/Navbar';
import LoginPopup from '../Components/Login/LoginPopup';
import { Footer } from '../Components/Footer/Footer';
import './ProtectedRoute.css'; // Import the CSS
import { useMobileWidth } from './Helper/HelperFunction';
import { MobileMenu } from '../Components/MobileMenu/MobileMenu';

export default function ProtectedRoute() {

  const isMobileWidth = useMobileWidth(); // Check if it's mobile width  
  const [isCollapsed, setIsCollapsed] = useState(isMobileWidth);

  return (
    <div>
      {/* Fixed Sidebar with dynamic class based on collapsed state */}
      <div className={!isMobileWidth ? (`fixed-sidebar ${isCollapsed ? 'collapsed' : ''}`) : (`mobile fixed-sidebar ${isCollapsed ? 'collapsed' : ''}`)}>
        <Sidebar
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      </div>


      {/* Fixed Navbar with dynamic class based on collapsed state */}
      <div className={!isMobileWidth ? (`fixed-navbar ${isCollapsed ? 'collapsed' : ''}`) : (`mobile fixed-navbar ${isCollapsed ? 'collapsed' : ''}`)}>
        <Navbar
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      </div>

      {/* Main Content Area with dynamic class based on collapsed state */}
      <div className={!isMobileWidth ? (`content-wrapper ${isCollapsed ? 'collapsed' : ''}`) : (`mobile content-wrapper ${isCollapsed ? 'collapsed' : ''}`)}>
        <div className='outlet'>
          <Outlet />
        </div>

        <Footer />
      </div>
    {
      isMobileWidth &&  <MobileMenu />
    }
      {/* Login Popup */}
      <LoginPopup />
    </div>
  );
}