import axios from "axios";
import { API_URL } from "../config/Api_Url";
import { addBearerToken, useSessionStorageData } from "../Utils/Helper/HelperFunction";

const DeleteRequest = async (service, path, data = {}) => {
  let sessionStorageData = useSessionStorageData();
  let token = sessionStorageData?.googleAccessToken || sessionStorageData?.apiUserData?.tokens?.access?.token;

  try {

    let header = {};

    if (token) {
      header = addBearerToken(header, token);
    }

    if (path != 'profile') {
      header["content-type"] = "application/json";
    }
    
    const response = await axios.delete(`${service}/${path}/`, {
      headers: header,
      data: data
    });

    return response;
  } catch (error) {
    throw error
  }
};

export default DeleteRequest;
