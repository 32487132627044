import '../Components/CardDetails/CardDetails.css'
import '../Components/Cards/Cards.css'
import '../Components/Crousel/Crousel.css';
import '../Components/DataTable/DataTable.css';
import '../Components/Footer/Footer.css'
import '../Components/Login/LoginPopup.css'
import '../Components/MyWallet/MyWallet.css'
import '../Components/Navbar/Navbar.css'
import "../Components/Pagination/Pagination.css";
import '../Components/Profile/Profile.css'
import '../Components/Settings/Settings.css'
import '../Components/Sidebar/Sidebar.css'
import '../Pages/ActiveBets/ActiveBets.css'
import '../Pages/Favourite/Favourite.css'
import '../Pages/Home/Home.css'
import '../Pages/Politics/Politics.css'
import '../Pages/Sports/Sports.css'
import '../Components/DepositMoney/DepositMoney.css';
import '../Components/Modal/BetPlaced.css';
import '../Components/ParleyBet/ParleyBet.css'
import '../Components/Modal/BetPlaced.css'

