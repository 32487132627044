import React, { createContext, useEffect, useState } from 'react'
import { PATHS, SERVICES } from '../../config/Api_Url';
import GetRequest from '../../services/GetRequest';
import { toast } from 'react-toastify';



export const BetContext = createContext();

export const UseContext = ({ children }) => {
    const [isMobile, setIsMobile] = useState('');
    const [isLogin, setIsLogin] = useState(false);
    const [isAuthenticatedWith, setIsAuthenticatedWith] = useState({
        normal: false,
        social: false
    })
    const [navSearchTerm, setNavSearchTerm] = useState('');

    useEffect(() => {
        if (isAuthenticatedWith.normal || isAuthenticatedWith.social) {
            setIsLogin(true)
        }
    }, [isAuthenticatedWith])

    const [profile, setProfile] = useState();
    const [showForm, setShowForm] = useState({
        login: false,
        signup: false,
        forgotPassword: false,
    });

    const [balanceLoading, setBalanceLoading] = useState(false)
    const [balance, setBalance] = useState('');

    const [comboPanalos, setComboPanalos] = useState([]);
    const [panaloLoading, setPanaloLoading] = useState(false)

    const getBalance = async () => {
        try {
            let balance = await GetRequest(SERVICES.paymentService, PATHS.get_balance);
            setBalance(balance?.data?.Balance);
        } catch (error) {
            toast.error('An error occured on fetch balance')
        }
    }

    useEffect(() => {
        if (isLogin) {
            getBalance()
        }
    }, [balanceLoading, isLogin])
    useEffect(() => {
        if (isLogin) {
            get_combo_panalos()
        }
    }, [panaloLoading, isLogin])

    const get_combo_panalos = async () => {
        try {
            let get_combo_panalos = await GetRequest(SERVICES.bettingService, PATHS.combo_panalo.get_combo_panalos);
            setComboPanalos(get_combo_panalos.data.data)
        } catch (error) {
            setComboPanalos([])
        }
    }

    return (
        <BetContext.Provider value={{
            isMobile, setIsMobile,
            isAuthenticatedWith, setIsAuthenticatedWith,
            profile, setProfile,
            showForm, setShowForm,
            isLogin, getBalance,
            balance, setBalanceLoading, balanceLoading,
            panaloLoading, setPanaloLoading,
            navSearchTerm, setNavSearchTerm,
            comboPanalos, setComboPanalos
        }}>
            {children}
        </BetContext.Provider>
    )
}
