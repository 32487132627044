// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Loader_loader__PC-Kr {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Loader_loaderMain__LPht2 {
  display: flex;
  justify-content: center;
  margin-top: 18%;
}
`, "",{"version":3,"sources":["webpack://./src/Loader/Loader.module.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":["\n\n.loader {\n  display: flex;\n  justify-content: center;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n.loaderMain {\n  display: flex;\n  justify-content: center;\n  margin-top: 18%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__PC-Kr`,
	"loaderMain": `Loader_loaderMain__LPht2`
};
export default ___CSS_LOADER_EXPORT___;
