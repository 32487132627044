import React, { useContext, useEffect, useState } from 'react'
import './Payment.css'
import { Images } from '../Images/Images'
import { ButtonComponent, LoaderComponent, to_locale_string } from '../../Utils/Helper/HelperFunction'
import GetRequest from '../../services/GetRequest'
import { PATHS, SERVICES } from '../../config/Api_Url'
import { useLocation, useNavigate } from 'react-router-dom'
import PostRequest from '../../services/PostRequest'
import { BetContext } from '../Context/UseContext'
export const Payment = () => {

    const { isLogin } = useContext(BetContext);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const [selectedSubPaymentMethod, setSelectedSubPaymentMethod] = useState("QRPH");
    const [activePayment, setActivePayment] = useState('Gcash/Maya/BankTransfer');
    const [isLoading, setIsloading] = useState({
        channel: false,
        checkout: false
    });
    const navigate = useNavigate();
    const location = useLocation();

    const value = location.state;
    const deposit_amount = value?.deposit_amount;

    const [paymentChannels, setPaymentChannels] = useState();

    const handleSetPayment = (data) => {
        setSelectedPaymentMethod(data)
        setActivePayment(data?.name);
    }

    const get_payment_channels = async () => {
        setIsloading({ ...isLoading, channel: true });
        try {
            let payment_channels = await GetRequest(SERVICES.paymentService, PATHS.payment_gateway_api.payment_channels);
            setPaymentChannels(payment_channels?.data?.data);
            setSelectedPaymentMethod(payment_channels?.data?.data[0]);
        } catch (error) {
        } finally {
            setIsloading({ ...isLoading, channel: false });
        }
    }

    const checkout = async () => {
        if ((activePayment !== 'Gcash/Maya/BankTransfer') && (selectedSubPaymentMethod === "QRPH")) {
            return;
        }

        setIsloading({ ...isLoading, checkout: true });
        const data = {
            amount: Number(deposit_amount || 0),
            payment_channel: selectedSubPaymentMethod
        }
        try {
            let resp = await PostRequest(SERVICES.paymentService, PATHS.payment_gateway_api.checkout, data);
            window.open(resp?.data?.data?.link, "_blank")
        } catch (error) {
        } finally {
            setIsloading({ ...isLoading, checkout: false });
        }
    }

    useEffect(() => {
        if (!isLogin) {
            navigate('home/all')
        }
        get_payment_channels();
    }, [])

    return (
        <div className='payment-main'>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <div onClick={() => navigate(-1)} className='card-back-div' style={{ height: '100%', paddingRight: '10px' }}><img src={Images.cards.cardDetailBack} alt="card-back-btn" /></div>
                <div className='payment-logo'>
                    <img src={Images.akalaKoLogo} alt="Logo Image" />
                </div>
            </div>

            <div className='payment-fund'>
                <h6>Deposit Funds</h6>
                <p>Securely add money to your account and start earning today.</p>
            </div>
            <div className='payment-amount'>
                <p className='amt'>Amount</p>
                <p className='value'>PHP {to_locale_string(deposit_amount || '0')}</p>
            </div>

            <div className='payment-select'>
                <p>Select Payment Method</p>
                <div className='box-main'>
                    {
                        isLoading.channel ? (
                            <SkeletonLoader type="" count={4} />
                        )
                            :
                            (paymentChannels && paymentChannels.map((data) => {
                                return <div onClick={() => handleSetPayment(data)} className={`box ${activePayment === data?.name ? 'active' : ''} `}>
                                    <div>
                                        <img src={data?.img_URL} alt={data?.name} />
                                    </div>
                                    <p>{data?.name}</p>
                                </div>
                            }))
                    }
                </div>
            </div>

            <div className='payment-cards'>
                <p>Select Sub-Payment Method</p>
                <div className='box-main sun-channel-card'>
                    {
                        isLoading.channel ? (
                            <SkeletonLoader type="box-1" count={3} />
                        )
                            :
                            (selectedPaymentMethod?.sub_categories?.map((data) => {
                                return <div className={`box-1 ${selectedSubPaymentMethod === data?.category ? "active-1" : ""}`} onClick={() => setSelectedSubPaymentMethod(data?.category)}>
                                    <div>
                                        <img src={data?.img_URL} alt="Cards" />
                                    </div>
                                    <p>{data?.category}</p>
                                </div>
                            }))
                    }

                </div>
            </div>

            <div className='proced-btn-div'>
                <ButtonComponent
                    onClick={()=>checkout()}
                    style={proceedDepositStyle}
                    disabled={isLoading.checkout}
                    text={
                        <div className='add-money-div'>
                            {isLoading.checkout ? <LoaderComponent isLoading={isLoading.checkout} /> : <span>
                                <img src={Images.payment.processDeposit} alt="Plus Icon" />
                            </span>}
                            <span>Proceed Deposit</span>
                        </div>
                    }
                />
            </div>
        </div>
    )
}
const proceedDepositStyle = {
    color: 'rgba(255, 255, 255, 1)',
    // background: 'rgba(28, 121, 71, 1)',
    background: 'rgba(65, 105, 225, 1)',
    border: 'none',
    borderRadius: '6px',
    margin: '0',
    display: 'flex',
    justifyContent: 'center',
}




const SkeletonLoader = ({ type = 'card', count = 5 }) => {
    const renderSkeletons = () => {
        const skeletonItems = [];
        for (let i = 0; i < count; i++) {
            skeletonItems.push(
                <div key={i} className={`skeleton-loader ${type} box`}>
                    <div className="skeleton-img"></div>
                    <div className="skeleton-text"></div>
                </div>
            );
        }
        return skeletonItems;
    };

    return <div className="skeleton-loader-container">{renderSkeletons()}</div>;
};
