import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react"; 
import { defaultLocale, dynamicActivate } from "./i18n.js"

// import * as serviceWorker from "./serviceWorker";

const I18nApp = () => {
  
  useEffect(() => {
    dynamicActivate(defaultLocale)
  }, [defaultLocale])

  return (
    <I18nProvider i18n={i18n}>
      <App />
    </I18nProvider>
  );
}

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nApp />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);



// Register the service worker
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
      navigator.serviceWorker
          .register("/serviceWorker.js")
          .then((registration) => {
              // console.log("Service Worker registered with scope:", registration.scope);
          })
          .catch((error) => {
              // console.error("Service Worker registration failed:", error);
          });
  });
}