// // src/hooks/useFirebaseMessaging.js
// import React, { useContext } from 'react'
// import { useEffect } from 'react';
// import { getToken, onMessage } from 'firebase/messaging';
// import { toast } from 'react-toastify';
// import { FIREBASE_VAPID_KEY, firebaseMessaging } from '../../Utils/firebase-config';
// import './Firebase.css'
// import PostRequest from '../../services/PostRequest';
// import { PATHS, SERVICES } from '../../config/Api_Url';
// import { BetContext } from '../Context/UseContext';

// const Message = ({ notification }) => (
//     <>
//         <div id="firebasenotificationHeader">
//             {notification.image && (
//                 <div id="firebaseimageContainer">
//                     <img src={notification.image} width={100} alt="Notification" />
//                 </div>
//             )}
//             <span>{notification.title}</span>
//         </div>
//         <div id="firebasenotificationBody">{notification.body}</div>
//     </>
// );


// export const Firebase = () => {

//     const { isLogin } = useContext(BetContext);
//     async function requestPermission() {
//         try {
//             // Requesting permission using Notification API
//             const permission = await Notification.requestPermission();

//             if (permission === "granted") {
//                 const token = await getToken(firebaseMessaging, {
//                     vapidKey: FIREBASE_VAPID_KEY,
//                 });

//                 if (token && isLogin) {
//                     // Optionally, send the token to the server here
//                     let body = {
//                         fcm_token: token
//                     }
//                     try {
//                         let sendFcmToken = await PostRequest(SERVICES.notificationService, PATHS.notification.fcm_token_save, body);
//                     } catch {
//                     }

//                 } else {
//                 }
//             }
//         } catch (error) {
//         }
//     }

//     onMessage(firebaseMessaging, (payload) => {

//         toast(<Message notification={payload.notification} />);
//     });

//     useEffect(() => {
//         requestPermission();
//     }, []);

// }
import { useEffect } from "react";
import { getToken, onMessage, isSupported } from "firebase/messaging";
import { toast } from "react-toastify";
import { FIREBASE_VAPID_KEY, firebaseMessaging } from '../../Utils/firebase-config';
import PostRequest from '../../services/PostRequest';
import { PATHS, SERVICES } from '../../config/Api_Url';

const Message = ({ notification }) => (
    <>
        <div id="firebasenotificationHeader">
            {notification.image && (
                <div id="firebaseimageContainer">
                    <img src={notification.image} width={100} alt="Notification" />
                </div>
            )}
            <span>{notification.title}</span>
        </div>
        <div id="firebasenotificationBody">{notification.body}</div>
    </>
);

async function initializeMessaging() {
    try {
        const supported = await isSupported(); // Check if messaging is supported
        if (!supported) {
            console.warn("Push notifications are not supported in this browser.");
            return null;
        }

        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const token = await getToken(firebaseMessaging, {
                vapidKey: FIREBASE_VAPID_KEY,
            });

            if (token) {
                // Send token to server
                const body = { fcm_token: token };
                try {
                    await PostRequest(SERVICES.notificationService, PATHS.notification.fcm_token_save, body);
                } catch (error) {
                    console.error("Error sending FCM token:", error);
                }
            }
        }
    } catch (error) {
        console.error("Error initializing Firebase Messaging:", error);
    }
}

export const Firebase = () => {
    useEffect(() => {
        initializeMessaging();
    }, []);

    useEffect(() => {
        const unsubscribe = onMessage(firebaseMessaging, (payload) => {
            toast(<Message notification={payload.notification} />);
        });

        return () => unsubscribe(); // Clean up listener
    }, []);
};
