import React, { lazy, Suspense, useContext, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { formatAndLowerPath, useSessionStorageData } from '../../Utils/Helper/HelperFunction';
import ProtectedRoute from '../../Utils/ProtectedRoute';
import { BetContext } from '../Context/UseContext';
import PostRequest from '../../services/PostRequest';
import { useAuth0 } from '@auth0/auth0-react';
import { PATHS, SERVICES } from '../../config/Api_Url';
// import { Home } from '../../Pages/Home/Home';
// import { Sports } from '../../Pages/Sports/Sports';
// import { ActiveBets } from '../../Pages/ActiveBets/ActiveBets';
// import { Politics } from '../../Pages/Politics/Politics';
// import { Entertainment } from '../../Pages/Entertainment/Entertainment';
// import { Weather } from '../../Pages/Weather/Weather';
// import { CardDetails } from '../CardDetails/CardDetails';
// import { Profile } from '../Profile/Profile';
// import { Settings } from '../Settings/Settings';
// import { Favourite } from '../../Pages/Favourite/Favourite';
// import { MyWallet } from '../MyWallet/MyWallet';
// import { PageCategories } from '../../Pages/PageCategories/PageCategories';
// import { ComboPanaloDetail } from '../ComboPanaoDetail/ComboPanaloDetail';
// import { AffiliatePartner } from '../../Pages/AffiliatePartner/AffiliatePartner';
import { Payment } from '../Payment/Payment';
// import PlayerReferrals from '../../Pages/PlayerReferrals/PlayerReferrals';
import Loader from '../../Loader/Loader';
import Styles from '../../Styles/Styles';
import AboutUs from '../../Pages/CompanyDetail/About';


const Home = lazy(() => import("../../Pages/Home/Home").then(module => ({ default: module.Home })));
const Sports = lazy(() => import("../../Pages/Sports/Sports").then(module => ({ default: module.Sports })));
const ActiveBets = lazy(() => import("../../Pages/ActiveBets/ActiveBets").then(module => ({ default: module.ActiveBets })));
const Politics = lazy(() => import("../../Pages/Politics/Politics").then(module => ({ default: module.Politics })));
const Entertainment = lazy(() => import("../../Pages/Entertainment/Entertainment").then(module => ({ default: module.Entertainment })));
const Weather = lazy(() => import("../../Pages/Weather/Weather").then(module => ({ default: module.Weather })));
const CardDetails = lazy(() => import("../CardDetails/CardDetails").then(module => ({ default: module.CardDetails })));
const Profile = lazy(() => import("../Profile/Profile").then(module => ({ default: module.Profile })));
const Settings = lazy(() => import("../Settings/Settings").then(module => ({ default: module.Settings })));
const Favourite = lazy(() => import("../../Pages/Favourite/Favourite").then(module => ({ default: module.Favourite })));
const MyWallet = lazy(() => import("../MyWallet/MyWallet").then(module => ({ default: module.MyWallet })));
const ParleyBet = lazy(() => import("../ParleyBet/ParleyBet").then(module => ({ default: module.ParleyBet })));
const PageCategories = lazy(() => import("../../Pages/PageCategories/PageCategories").then(module => ({ default: module.PageCategories })));
const ComboPanaloDetail = lazy(() => import("../ComboPanaoDetail/ComboPanaloDetail").then(module => ({ default: module.ComboPanaloDetail })));
const AffiliatePartner = lazy(() => import("../../Pages/AffiliatePartner/AffiliatePartner").then(module => ({ default: module.AffiliatePartner })));
// const Payment = lazy(() => import("../Payment/Payment").then(module => ({ default: module.Payment })));
const PlayerReferrals = lazy(() => import("../../Pages/PlayerReferrals/PlayerReferrals").then(module => ({ default: module.PlayerReferrals })));
const Terms = lazy(() => import("../../Pages/CompanyDetail/Terms/index"));
const Privacy = lazy(() => import("../../Pages/CompanyDetail/Privacy/index"));


export const RoutesAndLayouts = () => {

    let sessionStorageData = useSessionStorageData();

    const { isAuthenticated: googleIsAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const { isAuthenticatedWith, setIsAuthenticatedWith, isLogin,setShowForm } = useContext(BetContext);
    const location = useLocation();
    const handleSocialLogin = async () => {
        try {

            let token = await getAccessTokenSilently()
            sessionStorage.setItem('google-access-token', JSON.stringify(token));

            let body = {
                token: token,
                username: user.name
            }
            let res = await PostRequest(SERVICES.accountService, PATHS.socialLogin, body)

            sessionStorage.setItem('google-user-data', JSON.stringify(res.data.data))
            setIsAuthenticatedWith({
                normal: false,
                social: true
            })
        } catch (error) {
        }
    }

    const checkAuthState = async () => {
        if (googleIsAuthenticated) {
            await handleSocialLogin();
        } else if (sessionStorageData.apiUserData?.tokens?.access?.token) {
            setIsAuthenticatedWith({
                normal: true,
                social: false
            });
        } else {
            setIsAuthenticatedWith({
                normal: false,
                social: false
            });
        }
    };


    useEffect(() => {
        checkAuthState();
    }, [googleIsAuthenticated, sessionStorageData.apiUserData?.tokens?.access?.token]);

    let category = {
        // SPORTS: 'Sports',
        // POLITICS: 'Politics',
        // ENTERTAINMENT: 'Entertainment',
        // WEATHER: 'Weather',
        ESPORT: 'Esports',
        CELEBRITIES: 'Celebrities',
        WEATHER_AND_NATURAL_EVENTS: 'Weather and Natural Events',
        LOTTO: 'Lotto',
        TV_SHOWS: 'TV Shows',
        CRYPTOCURRENCIES: 'Cryptocurrencies',
        STOCKS: 'Stocks',
        SOCIAL_MEDIA: 'Social Media',
    }

    let route = Object.entries(category).map((category, index) => {
        let item = {
            key: category[0],
            value: category[1]
        }

        return {
            path: `${formatAndLowerPath(item.value)}/:category`,
            element: <PageCategories pageItems={item} />,
        }
    })

    const protectedRoute = [
        {
            path: 'home/:category',
            element: <Home />,
        },
        {
            path: ':page/:category/event-detail/:id',
            element: <CardDetails />
        },
        {
            path: ':page/:category/combo-panalo-detail/:id',
            element: <ComboPanaloDetail />
        },
        {
            path: 'combo-panalo',
            element: <ParleyBet />
        },
        {
            path: 'active-bets/:category',
            element: <ActiveBets />,
        },
        {
            path: 'sports/:category',
            element: <Sports />,
        },
        {
            path: 'politics/:category',
            element: <Politics />,
        },
        {
            path: 'entertainment/:category',
            element: <Entertainment />,
        },
        {
            path: 'weather/:category',
            element: <Weather />,
        },
        ...route,
        {
            path: 'profile/',
            element: <Profile />,
        },

        {
            path: 'player-referrals',
            element: <PlayerReferrals />
        },
        {
            path: 'affiliate-partner',
            element: <AffiliatePartner />
        },

        {
            path: 'settings',
            element: <Settings />,
        },
        {
            path: 'favourite',
            element: <Favourite />
        },
        {
            path: 'my-wallet',
            element: <MyWallet />
        },
        {
            path: 'payment',
            element: <Payment />
        },
        {
            path: "terms-and-condition",
            element: <Terms/>
        },
        {
            path: "/privacy-policy",
            element: <Privacy/>
        },
        {
            path: "/about-us",
            element: <AboutUs/>
        }
    ]


    return (
        <Suspense
            fallback={
                <div>
                    <Loader />
                </div>
            }
        >

            <Routes>
                {/* Public Routes */}
                {/* {publicRoutes.map((route, key) => <Route path={route.path} element={route.element} key={key} />)} */}
                {/* Redirect root path ("/") to "/home/all" */}
                <Route path="/" element={<Navigate to="/home/all" />} />

                {/* Protected Routes */}
                <Route path='/' element={<ProtectedRoute />} >
                    {
                        protectedRoute.map((route, key) => {
                            return <Route path={route.path} element={route.element} key={key} />
                        })
                    }
                </Route>

                {/* Redirect to login if no route matches */}
                <Route path="*" element={<Navigate to={(isAuthenticatedWith.social || isAuthenticatedWith.normal) ? "/home/all" : "/home/all"} />} />
            </Routes>
        </Suspense>
    );
};


