import axios from "axios";
import { addBearerToken, useSessionStorageData } from "../Utils/Helper/HelperFunction";
import { useAuth0 } from "@auth0/auth0-react";
const GetRequest = async (service, path, params) => {

  let sessionStorageData = useSessionStorageData();
  // const {user} = useAuth0()  

  let token = sessionStorageData?.googleAccessToken || sessionStorageData?.apiUserData?.tokens?.access?.token;

  try {
    let header = {
      "content-type": "application/json",
    };

    if (token) {
      header = addBearerToken(header, token);
    }

    const response = await axios.get(`${service}/${path}/`, {
      headers: header,
      params: params
    });
    return response;
  } catch (error) {
    throw error
    // toast.error(error.message);
  }
};

export default GetRequest;
