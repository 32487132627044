import React from 'react';
import { Images } from '../../../Components/Images/Images';
import '../Terms/index.css';

const data = [
    "Teka, akala mo ba traditional slots o casino ang AkalaKo88? Naku, hindi! Dito, talino at timing ang puhunan mo, hindi lang basta swerte.",
    "Predict the next reality show evictee, sports champion, next Manila Mayor, o kahit trending events na inaabangan ng lahat. Dito, ikaw ang magde-decide kung sino ang tatamaan ng hula mo! No spins, no random luck—puro thrill at saya lang!",
    "Kaya kung sawa ka na sa paulit-ulit na slots o casino games, try mo naman ang bago. AkalaKo88: ang tambayan kung saan bawat hula mo, may chance maging panalo!"
]
const AboutUs = () => {
    return (
        <>
            <div className='about-us-banner'>
                <img src={Images?.aboutus?.background} width="100%"/>
            </div>

            <div className='about-us-card'>
                <div className='d-flex justify-content-between align-items-center'>  
                    <p className='head'>AkalaKo88: Walang Spin o Slot, Pero Panalo Ka On the Spot!</p>
                    <img src={Images?.aboutus?.coin} />
                </div>
                <div className='d-flex flex-column gap-4 mt-4 '>
                    {
                        data?.map((data) => {
                            return <p className='sub-heads mb-0'>{data}</p>
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default AboutUs;