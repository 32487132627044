import React from 'react'
import './MobileMenu.css';
import { Images } from '../Images/Images';
import { useLocation, useNavigate } from 'react-router-dom';
export const MobileMenu = () => {

    const location = useLocation()
    const navigate = useNavigate()

    return (
        <div className='main-mobile-menu'>
            <div className='menu-img-div-main'>
                <div onClick={() => navigate('home/all')} className={`${location.pathname === '/home/all' ? 'border-menu' : ''}`}>
                    <div className={`menu-img-div`}>
                        <img src={location.pathname === '/home/all' ? Images.mobileMenu.homeActive : Images.mobileMenu.homeInActive} alt="home-active" />
                    </div>
                </div>
            </div>
            <div className='menu-img-div-main'>
                <div onClick={() => navigate('profile')} className={`${location.pathname === '/profile' ? 'border-menu' : ''}`}>
                    <div className={`menu-img-div`}>
                        <img src={location.pathname === '/profile' ? Images.mobileMenu.searchActive : Images.mobileMenu.searchInActive} alt="search-active" />
                    </div>
                </div>
            </div>
            <div className='menu-img-div-main'>
                <div onClick={() => navigate('my-wallet')} className={`${location.pathname === '/my-wallet' ? 'border-menu' : ''}`}>
                    <div className={`menu-img-div`}>
                        <img src={location.pathname === '/my-wallet' ? Images.mobileMenu.hotspotActive : Images.mobileMenu.hotspotInActive} alt="hotspot-active" />
                    </div>
                </div>
            </div>
            <div className='menu-img-div-main'>
                <div onClick={() => navigate('settings')} className={`${location.pathname === '/settings' ? 'border-menu' : ''}`}>
                    <div className={`menu-img-div`}>
                        <img src={location.pathname === '/settings' ? Images.mobileMenu.settingActive : Images.mobileMenu.settingInActive} alt="settings-active" />
                    </div>
                </div>
            </div>
        </div>
    )
}
