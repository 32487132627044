// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-main {
    border-top: 1px solid #FFFFFF;
    padding: 14px 30px;
    background: #FFFFFF;
}
.image-para{
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 0px;
    margin-top: 25px;
}
.header{
    font-size: 17px;
    font-weight: 500;
    color: #A9A9A9;
}
.links{
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: #666666;
}
.footer-image{
    width: 70px;
    cursor: pointer;
}
.copyright-line{
    color: #666666;
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".footer-main {\n    border-top: 1px solid #FFFFFF;\n    padding: 14px 30px;\n    background: #FFFFFF;\n}\n.image-para{\n    font-size: 14px;\n    font-weight: 400;\n    color: #666666;\n    line-height: 19px;\n    margin-bottom: 0px;\n    margin-top: 25px;\n}\n.header{\n    font-size: 17px;\n    font-weight: 500;\n    color: #A9A9A9;\n}\n.links{\n    text-decoration: none;\n    font-size: 14px;\n    font-weight: 500;\n    color: #666666;\n}\n.footer-image{\n    width: 70px;\n    cursor: pointer;\n}\n.copyright-line{\n    color: #666666;\n    font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
