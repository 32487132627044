 import axios from "axios";
 import { addBearerToken, useSessionStorageData } from "../Utils/Helper/HelperFunction";
 
 const PostRequest = async (service, path, data = {}) => {
   let sessionStorageData = useSessionStorageData();
   let token = sessionStorageData?.googleAccessToken || sessionStorageData?.apiUserData?.tokens?.access?.token
 
   try {
 
     let header = {};
 
     if (token) {
       header = addBearerToken(header, token);
     }
 
     if (path != 'profile') {
       header["content-type"] = "application/json";
     }
 
     const response = await axios.post(`${service}/${path}/`, data, {
       headers: header,
     });
 
     return response;
   } catch (error) {
     throw error
   }
 };
 
 export default PostRequest;
 