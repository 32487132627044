import React, { useContext, useEffect, useState } from "react";
import "./Navbar.css";
import { Images } from "../Images/Images";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ButtonComponent,
  convertToTitleCase,
  formatDate,
  formatPathName,
  generateHamBurgerPath,
  getUrlDetails,
} from "../../Utils/Helper/HelperFunction";
import Cookies from "js-cookie";
import { BetContext } from "../Context/UseContext";
import { useAuth0 } from "@auth0/auth0-react";
import auth0Config from "../../Utils/auth0-config";
import GetRequest from "../../services/GetRequest";
import { PATHS, SERVICES } from "../../config/Api_Url";
import PostRequest from "../../services/PostRequest";
import { toast } from "react-toastify";
import { defaultLocale, dynamicActivate } from "../../i18n";
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";

export const Navbar = ({ isCollapsed, setIsCollapsed }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    setIsAuthenticatedWith,
    profile,
    setProfile,
    setShowForm,
    isLogin,
    navSearchTerm,
    setNavSearchTerm,
    comboPanalos,
  } = useContext(BetContext);

  const { pageName, category, IsEventDetail } = getUrlDetails(
    location.pathname
  );

  const [language, setLanguage] = useState(defaultLocale);
  const handleChangeLang = (e) => {
    const { value } = e.target;
    dynamicActivate(value)
    localStorage.setItem("selectedLanguage", value)
    setLanguage(value)

    window.location.reload()
  }

  const hamBurgerPath = generateHamBurgerPath(
    pageName,
    category,
    IsEventDetail
  );

  const { logout, user } = useAuth0();
  const [notifications, setNotifications] = useState([])

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await PostRequest(SERVICES.accountService, PATHS.logout);
    } catch (error) { console.log(error) }

    localStorage.clear();
    sessionStorage.clear();
    setIsAuthenticatedWith({ normal: false, social: false });

    logout({ returnTo: auth0Config.redirectUri });
  };

  const getProfile = async () => {
    try {
      let profile = await GetRequest(SERVICES.accountService, PATHS.profile);
      setProfile(profile.data.data);
    } catch (error) { }
  };

  useEffect(() => {
    if (navSearchTerm && pageName !== "home") {
      setNavSearchTerm("");
    }
  }, [pageName, navSearchTerm]);

  useEffect(() => {
    if (isLogin) {
      getProfile();
      get_all_notification()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  const get_all_notification = async (status = "ALL") => {
    try {
      let body = {
        status: status
      }
      let get_all_notification = await GetRequest(SERVICES.notificationService, PATHS.notification.get_all_notification, body);
      setNotifications(get_all_notification.data.data)
    } catch (error) {
    }
  }

  const unreadLength = notifications.filter(notification => notification.status === 'unread').length;

  const read_notification = async (notification_id = "") => {
    try {

      let read_notification = await PostRequest(SERVICES.notificationService, `${PATHS.notification.read_notification}/${notification_id}`);
      get_all_notification()
    } catch (error) {
    }
  }

  const read_all_notification = async () => {
    try{
      let resp = await PostRequest(SERVICES.notificationService, PATHS.notification.read_all_notfication);
      get_all_notification();
    } catch(error){ }
  }

  return (
    <>
      <div className="navbar">
        <div className="breadcrumb">
          <div>{formatPathName(pageName)}</div>
          <div>{convertToTitleCase(hamBurgerPath)}</div>
        </div>
        <button className="nav-open-icon">
          <IconComponent
            onClick={() => {
              setIsCollapsed(false);
            }}
            icon={Images.sidebar.hamburgerOpen}
          />
        </button>
        {/* <div className="mobile-nav-logo">
          <div onClick={() => navigate('home/all')}>
            <img src={Images.akalaKoLogo} alt="mobile-logo" />
          </div>
        </div> */}
        <header className="header" id="header">
          <nav>
            {pageName === "home" && (
              <form className="search-form">
                <button type="submit" className="search-submit" disabled>
                  <img src={Images.navbar.searchIcon} alt="Search Icon" />
                </button>
                <input
                  value={navSearchTerm}
                  onChange={(e) => setNavSearchTerm(e.target.value)}
                  type="text"
                  name="search"
                  className="search-input"
                  placeholder={t`search events...`}
                  autoFocus
                />
              </form>
            )}
            <select value={language} onChange={handleChangeLang} className="language-select">
              <option value="en">English</option>
              <option value="fil">Filipino</option>
            </select>
            {!isLogin ? (
              <>
                <ButtonComponent
                  onClick={() => setShowForm({ login: true })}
                  style={{
                    color: "rgba(210, 43, 43, 1)",
                    background: "none",
                  }}
                  text={t`Login`}
                />
                <div className="nav-btn-group">
                  <ButtonComponent
                    onClick={() => setShowForm({ signup: true })}
                    style={{
                      background: "rgba(210, 43, 43, 1)",
                      color: "rgba(255, 255, 255, 1)",
                    }}
                    text={t`Sign Up`}
                  />
                </div>
              </>
            ) : (
              <div className="profile-div">
                {
                  comboPanalos?.combo?.length > 0 && (
                    <div className="dropdown nav-drop-item">
                      <div data-bs-toggle="dropdown" aria-expanded="false" id="parleyTray" >
                        <div className="" type="button" >
                          <img src={Images.parley.parleyIcon} alt="notification icon" />
                        </div>
                        {
                          comboPanalos.combo.length > 0 &&
                          <div className="combo-counter">
                            {comboPanalos.combo.length}
                          </div>
                        }
                      </div>
                      <ul
                        className="dropdown-menu parley-notification-dropdown"
                        aria-labelledby="parleyTray">
                        <div className="combo-panalo-head-cont">
                          <div className="notification-txt nt2">Combo Panalo</div>
                          <button className="parley-v-btn" onClick={() => navigate("combo-panalo")}>
                            <img src={Images.parley.cblBtnRedIcon} alt="button-icon" />
                            View
                          </button>
                        </div>

                        {
                          comboPanalos.combo?.map((comboPanalo, index) => {
                            return (
                              <div key={index} className="cp-bet-list-cont">
                                <div className="cp-bet-list">
                                  <div className="parley-card-imge">
                                    <img src={comboPanalo.eventId?.image} alt="image" />
                                  </div>
                                  <div className="par-title2">
                                    {comboPanalo.eventId?.title}
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }

                        <button
                          className="cbl-btn"
                          onClick={() => navigate("combo-panalo")}>
                          <img src={Images.parley.cblBtnIcon} alt="button-icon" />
                          View
                        </button>
                      </ul>
                    </div>
                  )
                }
                <div className="dropdown nav-drop-item">
                  <div
                    className="notification"
                    type="button"
                    data-bs-auto-close="outside"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="dropdownMenuClickableInside">
                    <img
                      src={Images.navbar.notificationIcon}
                      alt="notification icon"
                    />
                    {
                      unreadLength > 0 &&
                      <div className="notification-counter">
                        {unreadLength}
                      </div>
                    }
                  </div>
                  <ul
                    className="dropdown-menu notification-dropdown"
                    onClick={(e) => e.stopPropagation()}
                    aria-labelledby="dropdownMenuClickableInside">
                    <div className="notification-head">
                      <div className="notification-txt p-0">Notification</div>
                      <div className="read-all" onClick={()=>read_all_notification()}>Read ALL</div>
                    </div>
                    <div className="not-li-main">
                      {
                        notifications?.length > 0 ? notifications.sort((a, b) => {
                          const aUnread = a.status === 'unread' ? 1 : 0;
                          const bUnread = b.status === 'unread' ? 1 : 0;
                          return bUnread - aUnread;
                        }).map((notification, index) => {
                          return (
                            <li className={`not-li ${notification.status}`} key={index}>
                              <div className="mark-read-div">
                                <p className="not-head">{notification.title}</p>
                                {
                                  notification.status === 'unread' && <p onClick={() => read_notification(notification._id)} className="mark-read">Mark Read</p>
                                }
                              </div>
                              <p className="not-para">
                                {notification.title}
                              </p>
                              <p className="not-date">
                                {`${formatDate(notification.createdAt).slice(0, 12)} | ${formatDate(notification.createdAt).slice(12, 20)}`}
                              </p>
                            </li>
                          );
                        }) : <div className="blank_message">
                            <span><Trans>No Notification Yet</Trans></span>
                        </div>
                      }
                    </div>
                  </ul>
                </div>
                <div className="dropdown">
                  <div
                    className="profile "
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <img
                      src={
                        profile?.image ||
                        user?.picture ||
                        Images.profile.profileImg
                      }
                      alt="profile-img"
                      referrerPolicy="no-referrer"
                    />
                  </div>

                  <ul className="dropdown-menu profile-dropdown">
                    <li onClick={() => navigate("profile")}>
                      <a className="dropdown-item profile-btn">
                        <div>
                          <div
                            className="profile-icon-div"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <img
                              className=""
                              src={Images.navbar.profileIcon}
                              alt="profile-img"
                            />
                          </div>
                          <div className="dropdown-txt"><Trans>Profile</Trans></div>
                        </div>
                      </a>
                    </li>
                    <li onClick={() => navigate("my-wallet")}>
                      <a className="dropdown-item profile-btn">
                        <div>
                          <div
                            className="profile-icon-div"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <img
                              className=""
                              src={Images.navbar.walletIcon}
                              alt="wallet icon"
                            />
                          </div>
                          <div className="dropdown-txt"><Trans>My Wallet</Trans></div>
                        </div>
                      </a>
                    </li>
                    {
                      (isLogin && !profile?.isAffiliate) && (
                        <li onClick={() => navigate('player-referrals')}>
                          <a className="dropdown-item profile-btn" >
                            <div>
                              <div className='profile-icon-div' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className='' src={Images.navbar.referIcon} alt="referral icon" />
                              </div>
                              <div className='dropdown-txt'><Trans>Refer & Win</Trans></div>
                            </div>
                          </a>
                        </li>
                      )}
                    {
                      (isLogin && profile?.isAffiliate) &&

                      <li onClick={() => navigate('affiliate-partner')}>
                        <a className="dropdown-item profile-btn" >
                          <div>
                            <div className='profile-icon-div' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img className='' src={Images.navbar.affiliateIcon} alt="affiliate icon" />
                            </div>
                            <div className='dropdown-txt'><Trans>Affiliate Partner</Trans></div>
                          </div>
                        </a>
                      </li>
                    }

                    <li onClick={() => navigate("favourite")}>
                      <a className="dropdown-item profile-btn">
                        <div>
                          <div
                            className="profile-icon-div"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <img
                              className=""
                              src={Images.navbar.starIcon}
                              alt="profile-img"
                            />
                          </div>
                          <div className="dropdown-txt"><Trans>Favourite</Trans></div>
                        </div>
                      </a>
                    </li>
                    <li onClick={() => navigate("settings")}>
                      <a className="dropdown-item profile-btn mb-2">
                        <div>
                          <div
                            className="profile-icon-div "
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <img
                              src={Images.navbar.settingIcon}
                              alt="profile-img"
                            />
                          </div>
                          <div className="dropdown-txt"><Trans>Settings</Trans></div>
                        </div>
                      </a>
                    </li>
                    <li className="">
                      <a className="dropdown-item profile-btn profile-logout-btn pt-2">
                        <div onClick={(e) => handleLogout(e)}>
                          <div
                            className="profile-icon-div "
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <img
                              src={Images.navbar.logoutIcon}
                              alt="profile-img"
                            />
                          </div>
                          <div className="logout-txt">
                            <Trans>Logout</Trans>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </nav>
        </header>
      </div>
    </>
  );
};

function IconComponent({ onClick, icon }) {
  return <img onClick={onClick} src={icon} alt="icon" />;
}
