import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_VAPID_KEY
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};


const FIREBASE_VAPID_KEY = REACT_APP_FIREBASE_VAPID_KEY

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);
logEvent(firebaseAnalytics, 'notification_received');
// Messaging service
// const firebaseMessaging = ()=> {
//   let message;
//   try{
//     message = getMessaging(firebaseApp);
//   } catch(error){
//     console.log(error);
//   }
//   return message;
// }

const firebaseMessaging = getMessaging(firebaseApp);
export { firebaseApp, firebaseAnalytics, firebaseMessaging, FIREBASE_VAPID_KEY };