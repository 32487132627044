import React from 'react'
import './Footer.css'
import { Images } from '../Images/Images'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/react/macro'
import { t } from '@lingui/core/macro'

const linksUrls = [
    {
        name: t`Contact us`,
        size: 2,
        link: [
            {
                image: Images.footer.TelegramLogo,
                name: t`Telegram`,
                link: "https://t.me/BizAK88"
            },
            {
                image: Images.referal.Facebook,
                name: t`Facebook`,
                link: "https://www.facebook.com/akalako88/"
            },
            {
                image: Images.referal.Insta,
                name: t`Instagram`,
                link: "https://www.instagram.com/akalako88/"
            }
        ]
    },
    {
        name: t`Company`,
        size: 3,
        link: [
            {
                name: t`About Us`,
                link: "/about-us"
            },
            {
                name: t`Terms & Conditions`,
                link: "/terms-and-condition"
            },
            {
                name: t`Privacy Policy`,
                link: "privacy-policy"
            }
        ]
    }
]
const image = {
    name: t`Payment Methods`,
    link: [
        Images.footer.GCash,
        Images.footer.Maya,
        Images.footer.Qrph,
        Images.footer.Cebuana,
        Images.footer.InstaPay,
        Images.footer.Bdo,
        Images.footer.SevenEleven,
    ]
}
export const Footer = () => {
    return (
        <>
            <div className='row m-0 mt-4 footer-main'>
                <div className='col-md-4 col-sm-12'>
                    <img src={Images.akalaKoLogo} width="150px"/>
                    <p className='image-para'><Trans>AkalaKo88 is a fun and exciting prediction platform where you can bet on a wide variety of events—ranging from sports, TV shows, politics, weather, and more! Use your knowledge and intuition to predict outcomes and win real big! Its easy to play and easy to win!</Trans></p>
                </div>
                {
                    linksUrls?.map((data, index)=>{
                        return <div key={index} className={`px-3 py-2 col-6 col-md-${data?.size}`}>
                            <p className='header'>{data?.name}</p>
                            {
                                data?.link?.map((data, index)=>{
                                    return <div key={index} className='d-flex mb-3 gap-1 align-items-center'>
                                        {data.image && <img src={data.image} alt="" width="32px" />}
                                        <Link className='links' to={data?.link}>{data.name}</Link>
                                    </div>
                                })
                            }
                        </div>
                    })
                }
                <div className='col-md-3 col-12 px-3 py-2'>
                    <p className='header'>{image?.name}</p>
                    <div className='d-flex gap-3'>
                        <div className='d-flex flex-column gap-2'>
                            <Link target='_blank' to="https://new.gcash.com/"><img className='footer-image' src={Images.footer.GCash} alt=""/></Link>
                            <Link target='_blank' to="https://www.maya.ph/"><img className='footer-image' src={Images.footer.Maya} alt=""/></Link>
                            <Link target='_blank' to="https://www.paymongo.com/qrph"><img className='footer-image' src={Images.footer.Qrph} alt=""/></Link>
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <Link target='_blank' to="https://cebuanalhuillier.com/"><img className='footer-image' src={Images.footer.Cebuana} alt=""/></Link>
                            <img className='footer-image' src={Images.footer.InstaPay} alt=""/>
                            <Link target='_blank' to="https://www.bdo.com.ph/personal"><img className='footer-image' src={Images.footer.Bdo} alt=""/></Link>
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <img className='footer-image' src={Images.footer.SevenEleven} alt=""/>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <span className='copyright-line'>&#169; All Rights Reserved @ 2024</span>
                </div>
            </div>
        </>
    )
}
